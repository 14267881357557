<template lang="pug">
.page
  .memberList(v-loading="loading")
    el-card( class="memberItem" v-for="i in memberList")
      .top
        .phone {{i.phoneNumber}}
        .role 
          el-tag 管理员
      .bottom
        .name {{i.wxNickname}}
  .pagination
    el-pagination(layout="total, prev, pager, next" background v-model:page-size="pagination.size" :pager-count="isMobile?3:5" :page-sizes="[10, 20, 50, 100]" :total="pagination.total" v-model:current-page="pagination.page" @current-change="getList")
  .footer(@click="router.push('/member/add')") 添加人员
</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'

const loading = ref(false)
const { fetch, router, message, ElMessageBox } = inject('global')
const memberList = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 10
})

// 获取门店列表
function getList() {
  loading.value = true
  let params = {
    ...pagination.value
  }
  fetch
    .get(`/boom-mix-biz-service/app/commercialPlazaUserAssoc/pageByCommercialPlazaId`, { params })
    .then(res => {
      memberList.value = res.list
      pagination.value.total = Number(res.total)
    })
    .finally(() => {
      loading.value = false
    })
}

async function initData() {
  pagination.value.page = 1
  getList()
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.memberList {
  --gutter: 10px;
  --logoSize: 80px;
  --checkboxSize: 50px;
  --size-text-title: 18px;
  --size-text-desc: 14px;
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  .memberItem {
    width: 100%;
    position: relative;
    line-height: 30px;
    .top {
      .role {
        position: absolute;
        right: 20px;
        top: 18px;
      }
    }
    .bottom {
      color: #999;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.footer {
  text-align: center;
  color: #fff;
  background-color: var(--color-red);
  position: fixed;
  bottom: 0;
  z-index: 3000;
  width: 100%;
  line-height: 60px;
  cursor: pointer;
}

.isMobile {
  .memberList {
    --gutter: 10px;
    --logoSize: 40px;
    --checkboxSize: 30px;
    --size-text-title: 14px;
    --size-text-desc: 12px;
  }
}
</style>
